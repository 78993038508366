<template>
  <div id="app">
    <div class="app-wrap">
      <router-view/>
    </div>
  </div>
</template>

<script>
export default {
  components: {

  },
  mounted () {
    let url = '/api/doodle/init'
    $.ajax({
      type: 'POST',
      url: url
    }).done((data) => {
      $.ajaxSetup({
        headers: { 'Kurukku-Kuri': data.kurukkuKuri }
      })
      this.$store.commit('initApp')
    }).fail((jqXHR, textStatus, errorThrown) => {
      window.jda.exception(url + ': ' + errorThrown)
    })
  }
}
</script>

<style lang="scss">
  html {
    position: relative;
    min-height: 0;
    overflow-x: auto;
  }

  body {
    overflow-x: auto;
    background-color: white;
  }
  #app {

  }

  .app-wrap {
    padding-bottom: 0;
    margin-bottom: 0;
  }
</style>
